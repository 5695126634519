import m from "mithril";
import { App } from "../gifcap";
import Button from "../components/button";
import View from "../components/view";

const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

interface StartViewAttrs {
  readonly app: App;
}

export default class StartView implements m.ClassComponent<StartViewAttrs> {
  private readonly app: App;

  constructor(vnode: m.CVnode<StartViewAttrs>) {
    this.app = vnode.attrs.app;
  }

  view() {
    return m(View, [
      isMobile ? m("p", "Sorry, mobile does not support screen recording.") : undefined,
      isMobile
        ? undefined
        : m(Button, {
            label: "Start Screen Recording (.gif)",
            icon: "play",
            onclick: () => this.app.startRecording(),
            primary: true,
          }),
        m("div", {style: {"margin-top": "100px", "font-size": "12px"}}, [
          m("h4", "GIF recording made easy"),
          m("p", "1. Keep it short"),
          m("p", "2. Resize window to HD 720p (1280 x 720 pixels) before recording"),
          m("p", "3. Record entire screen and crop / trim recording afterwards in preview"),
          m("p", "4. Press render and download your recording as easy to share GIF"),
          m("p", "5. Share GIF on linkedIn or add to your powerpoint to make an impression!"),
        ]),
    ]);
  }
}
